<template>
  <PageCard
    :pageName="
      partnerSite
        ? 'The Ethos Performance Intelligence'
        : 'Institution Analytics'
    "
    :iconClass="partnerSite ? [] : ['cuis-chart-line', 'card-icon-font']"
  >
    <template slot="page_content">
      <div v-if="partnerSite">
        <div class="row">
          <div class="col-sm-12">
            <p class="px-1">
              Launch the tools below to generate instant insights and drive the
              performance of your institution. Leverage data-driven consultative
              assessments and industry-leading peer analytics to outperform your
              competition.
            </p>
          </div>
        </div>

        <b-row class="about-row">
          <b-col cols="4" class="px-3">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
              footer-class="d-none"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Advisor</b></span>
                  : Instantly generate insights with an action plan to expertly
                  manage your institution.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/advisor/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Advisor</router-link
                >
                <b-card-img
                  src="../../../../static/img/ethos/advisor.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="4" class="px-1">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Reporter</b></span>
                  : Instantly generate presentations and custom reports for any
                  meeting scenario.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/reporter/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Reporter</router-link
                >
                <b-card-img
                  src="../../../../static/img/ethos/reporter.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row class="about-row">
          <b-col cols="3" class="px-1">
            <b-card
              class="std-border mb-0 pb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <div slot="header">FIN Query&reg;</div>
              <b-card-body class="p-0 flex-card-body ">
                <div class="content-div">
                  <p>
                    Deploy FIN Query® to leverage the powerful combination of
                    firmographic, technographic, performance, client offering
                    and contact data for the most comprehensive peer group
                    construction, M&A target identification or industry research
                    available anywhere.
                  </p>

                  <p>
                    In three easy steps, FIN Query allows users to (1) filter
                    financial institutions, (2) select additional institution or
                    contact data, and (3) instantly export the customized data
                    for further review or analysis.
                  </p>
                  <b-card-img
                    src="../../../../static/img/fin-advanced-query.png"
                    class="rounded-0 my-2"
                  />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="3" class="px-1">
            <b-card
              class="std-border mb-0 pb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <div slot="header">FIN Advisor&reg;</div>
              <b-card-body class="p-0 flex-card-body ">
                <div class="content-div">
                  <p>
                    Deploy FIN Advisor® to instantly analyze and assess any
                    institution’s strategy, performance and offering. Instantly
                    generate a complete consultative assessment of any U.S.
                    financial institution’s profit, growth, risk, and customer
                    offering generating primary opportunity themes, specific
                    strengths/weaknesses and custom recommendations.
                  </p>

                  <p>
                    FIN Advisor positions your team to quickly and effectively
                    formulate strategy and prioritize resource allocation
                    tocompete in the era of digital transformation.
                  </p>
                  <b-card-img
                    src="../../../../static/img/advisor.png"
                    class="rounded-0 my-2"
                  />
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col cols="3" class="px-1">
            <b-card
              class="std-border mb-0 pb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <div slot="header">FIN Reporter&reg;</div>
              <b-card-body class="p-0 flex-card-body ">
                <div class="content-div">
                  <p>
                    Utilize FIN Reporter® to access existing or create
                    customized reports leveraging FI Navigator’s unrivaled
                    financial institution performance and offering data.
                  </p>
                  <p>
                    FIN Reporter delivers complete control to users on reports,
                    metrics, peers, time spans and graphics to create custom
                    content for any meeting scenario.
                  </p>

                  <p>
                    Transform unprecedented data into clarifying analysis on any
                    financial institution.
                  </p>
                  <b-card-img
                    src="../../../../static/img/report.png"
                    class="rounded-0 my-2"
                  />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="3" class="px-1">
            <b-card
              class="std-border mb-0 pb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <div slot="header">FIN Industry Reporter&reg;</div>
              <b-card-body class="p-0 flex-card-body ">
                <div class="content-div">
                  <p>
                    Uniquely harness industry data to inform your strategy by
                    illuminating the primary factors influencing financial
                    institution performance.FIN Industry Reporter® allows users
                    to analyze the banking industry in an unprecedented manner.
                    Create FI peer groups, segmentation schemes and custom
                    reports towards identifying the critical factors driving
                    industry performance.
                  </p>

                  <p>
                    Construct institution groups consisting of banks, credit
                    unions or all institutions that can be compared to other
                    peer groups or stratified by your segmentation methodology.
                  </p>

                  <p>
                    Users can deploy segmentation ranging from market size to
                    core technology deployed to stratify performance.
                  </p>
                  <b-card-img
                    src="../../../../static/img/industry_reporter.png"
                    class="rounded-0 my-2"
                  />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutInstitutionAnalytics',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'Institution Analytics'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped>
.flex-card-body {
  display: flex;
  flex-direction: column;
  .content-div {
    flex-grow: 1;
  }
}
</style>
